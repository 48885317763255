import { GetStaticProps } from 'next';
import React, { useEffect } from 'react';

import {
  CceContentDataContextProvider,
  CceLandingLayout,
  CceLandingTemplate,
  dispatchCCEAnalyticsEvent,
  CCEAnalyticsEvent,
  extractRoadshowsUids,
  extractStagesUids,
} from '@features/cce';
import { hideCrispChat, useIsomorphicLayoutEffect } from '@features/common';
import { CCEDocument, CCERoadshowsSchoolDocument, CCEStageDocument } from '@globalTypes/prismic/documents';
import usePrismicMetaTags from '@hooks/usePrismicMetaTags';
import { featureChecker, FeatureGroup } from '@services/featureChecker';
import PageHead from '@templates/PageHead';
import { errorHandler } from '@utils/error';

import { createClient } from '../prismicio';

type CCEIndexPageProps = {
  cceDocument: CCEDocument | null;
};

const CCEIndexPage = ({ cceDocument }: CCEIndexPageProps): JSX.Element | null => {
  const { isAvailable } = featureChecker;
  const isCCEAvailable = isAvailable(FeatureGroup.CCE, 'AVAILABLE');

  useEffect(() => {
    hideCrispChat();
    dispatchCCEAnalyticsEvent({ type: CCEAnalyticsEvent.CCE_OPENED });
  }, []);

  const metaTags = usePrismicMetaTags(cceDocument?.data.metaTags);

  if (!isCCEAvailable || !cceDocument) return null;

  return (
    <CceContentDataContextProvider cceContentData={cceDocument.data}>
      <PageHead
        title={metaTags.title}
        ogImage={metaTags.ogImage}
        description={metaTags.description}
        keywords={metaTags.keywords}
      />
      <CceLandingLayout>
        <CceLandingTemplate />
      </CceLandingLayout>
    </CceContentDataContextProvider>
  );
};

export const getStaticProps: GetStaticProps<CCEIndexPageProps> = async ({ previewData }) => {
  const prismicClient = createClient({ previewData });

  const getCceDocument = async (): Promise<CCEDocument | null> => {
    try {
      const document = await prismicClient.getSingle<CCEDocument>('cce');

      const roadshowSchoolUids = extractRoadshowsUids(document.data.roadshowSchools);
      const stagesUids = extractStagesUids(document.data.stages);

      const stagesDocuments = await prismicClient.getAllByUIDs<CCEStageDocument>('cceStage', stagesUids);

      const roadshowSchoolDocuments = await prismicClient.getAllByUIDs<CCERoadshowsSchoolDocument>(
        'roadShowsSchool',
        roadshowSchoolUids,
      );

      const cceDocumentStages = stagesDocuments.map(stage => ({ stage }));
      const cceDocumentRoadshowSchools = roadshowSchoolDocuments.map(roadshowSchool => ({ roadshowSchool }));

      return {
        ...document,
        data: {
          ...document.data,
          stages: cceDocumentStages,
          roadshowSchools: cceDocumentRoadshowSchools,
        },
      };
    } catch (error) {
      errorHandler(error);
      return null;
    }
  };

  const cceDocument = await getCceDocument();

  return {
    props: {
      cceDocument,
    },
  };
};

export default CCEIndexPage;
