import Head from 'next/head';

type PageHeadProps = {
  title?: string;
  description?: string;
  ogImage?: string;
  keywords?: string;
};

const PageHead = ({ title, description, ogImage, keywords }: PageHeadProps): JSX.Element => {
  return (
    <Head>
      <meta property="og:type" content="article" key="og:type" />
      {title && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} key="og:title" />
          <meta name="twitter:title" content={title} key="twitter:title" />
        </>
      )}
      {description && (
        <>
          <meta name="description" content={description} key="description" />
          <meta property="og:description" content={description} key="og:description" />
          <meta name="twitter:description" content={description} key="twitter:description" />
        </>
      )}
      {keywords && <meta name="keywords" content={keywords} />}
      {ogImage && (
        <>
          <meta name="twitter:image" content={ogImage} />
          <meta property="og:image" content={ogImage} key="og:image" />
        </>
      )}
    </Head>
  );
};

export default PageHead;
