import { useMemo } from 'react';

import { MetaTagsGroupField } from '@globalTypes/prismic/documents';

const usePrismicMetaTags = (
  prismicMetaTags?: MetaTagsGroupField,
): {
  title?: string;
  description?: string;
  ogImage?: string;
  keywords?: string;
} => {
  return useMemo(() => {
    if (!prismicMetaTags) {
      return {};
    }

    return {
      title: prismicMetaTags.find(t => t.title)?.title ?? '',
      description: prismicMetaTags.find(t => t.description)?.description ?? '',
      ogImage: prismicMetaTags.find(t => Object.keys(t.ogImage).length > 0)?.ogImage?.url ?? '',
      keywords: prismicMetaTags.find(t => t.keywords)?.keywords ?? '',
    };
  }, [prismicMetaTags]);
};

export default usePrismicMetaTags;
